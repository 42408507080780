<template>
    <div class="mx-1 my-1">
        <v-tabs color="primary accent-4" right v-if="isSettingsView">
            <v-tab>
                English
            </v-tab>
            <v-tab>
                Spanish
            </v-tab>

            <v-tab-item
                v-for="(lang, index) in ['English', 'Spanish']"
                :key="lang"
            >
                <v-card class="fill-height elevation-0" :loading="loading">
                    <v-card-title v-if="viewDetails">
                        <h3>Template</h3>
                        <v-spacer />
                        <v-btn
                            color="primary"
                            dark
                            rounded
                            @click="saveTemplate"
                            :loading="loading"
                        >
                            SAVE
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <div v-if="isSettingsView">
                            <vue-editor
                                :editor-toolbar="customToolbar"
                                v-model="leadSetting.template[index]"
                                :style="{ height: '75%' }"
                            />
                        </div>
                        <div class="my-4" v-if="viewDetails">
                            <v-row>
                                <v-col cols="12" class="py-1">
                                    <p>
                                        The autocomplete data with the
                                        information of each lead will be
                                        indicated with special names inside ${}.
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    xl="3"
                                    lg="3"
                                    md="3"
                                    class="my-0 py-0"
                                    v-for="keyWord of leadSetting.keyWords"
                                    :key="keyWord.word"
                                >
                                    <p>
                                        <strong>{{ keyWord.word }}:</strong>
                                        {{ keyWord.description }}
                                    </p>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs>
        <div v-if="!isSettingsView">
            <vue-editor
                :editor-toolbar="customToolbar"
                v-model="currentTemplate"
                :style="{ height: '75%' }"
            />
        </div>
    </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'LeadTemplate',
    props: {
        setting: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            default: () => 'reject',
        },
        language: {
            type: String,
            default: '',
        },
    },
    components: {
        VueEditor,
    },
    data: () => ({
        leadSetting: {
            template: ['', ''],
        },
        templateHeight: 0,
        loading: false,
        template: '',
        customToolbar: [
            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            [{ align: '' }, { align: 'center' }, { align: 'right' }],
            ['blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ],
        viewDetails: true,
    }),
    computed: {
        currentTemplate: {
            get() {
                const templates = {
                    English: this.leadSetting.template[0],
                    Spanish: this.leadSetting.template[1],
                }
                return templates[this.language] || ''
            },
            set(value) {
                const index = ['English', 'Spanish'].indexOf(this.language)
                if (index !== -1) {
                    this.leadSetting.template[index] = value
                }
            },
        },
        isSettingsView() {
            if (
                this.$router.currentRoute.name.includes(
                    'lead-notifications-reject'
                ) ||
                this.$router.currentRoute.name.includes(
                    'lead-notifications-bidding'
                )
            ) {
                return true
            } else {
                return false
            }
        },
    },
    mounted() {
        if (!this.$router.currentRoute.name.includes('lead-notifications')) {
            this.viewDetails = false
        }

        // set setting
        this.leadSetting = this.setting.leads.find(
            lead => lead.title == this.type
        )

        if (!this.leadSetting) {
            this.leadSetting = { template: ['', ''] }
        } else if (!Array.isArray(this.leadSetting.template)) {
            this.$set(this.leadSetting, 'template', ['', ''])
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async saveTemplate() {
            try {
                this.loading = true
                await API.updateNotificationTemplate({
                    id: this.setting.id,
                    leads: this.leadSetting,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
